.feature-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.feature {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-content {
  color: white;
  text-align: center;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 5px;
}
