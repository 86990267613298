#services {
    background-color: #eaeaea;
    padding: 20px;
    text-align: center;
  }
  
  .service {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: box-shadow 0.3s ease;
  }
  
  .service:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  }
  