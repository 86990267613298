.portfolio-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.portfolio-category h3 {
  cursor: pointer;
  background-color: #f7f7f7;
  padding: 10px;
  margin-bottom: 5px;
  border: none;
  text-align: left;
  font-size: 1.2em;
  transition: background-color 0.3s;
}

.portfolio-category h3:hover,
.portfolio-category h3.active {
  background-color: #dedede;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  margin-top: 10px;
}

.portfolio-item {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.portfolio-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.portfolio-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.portfolio-item iframe {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .portfolio-grid {
    grid-template-columns: 1fr;
  }
}
