#contact {
  background-color: #f8f8f8;
  padding: 40px 20px;
  text-align: center;
  color: #333;
}

#contact h2 {
  color: #0a0a0a;
  margin-bottom: 20px;
  font-size: 2rem;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.contact-item {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.contact-item p {
  margin: 0;
}

.contact-item a {
  color: #0066cc;
  text-decoration: none;
}

.contact-item a:hover {
  text-decoration: underline;
}

.google-map {
  margin-top: 30px;
}

.contact-form {
  margin-top: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.contact-form h3 {
  margin-bottom: 20px;
}

.contact-form input, 
.contact-form textarea {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  padding: 15px 30px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.contact-form button:hover {
  background-color: #005bb5;
}
