footer {
    background-color: #0a0a0a;
    color: white;
    padding: 1rem;
    text-align: center;
    margin-top: auto;
  }
  
  footer p {
    margin: 0;
  }
  